@use '../../styles/theme.scss';

.AuthTextInput {
  flex-shrink: 0;

  .input {
    // Layout
    display: flex;
    width: 100%;
    height: 55px;
    padding: 16px 24px 16px 24px;
    align-items: center;

    // Style
    border-radius: 4px;
    border: 1px solid theme.$color--stroke;

    &.highlight {
      border: 1px solid theme.$color--red;
    }

    &.error {
      border: 1px solid theme.$color--red;
    }
  }

  .errorMessage {
    color: theme.$color--red;
  }
}
