@use '../../styles/mixins.scss';
@use '../../styles/theme.scss';

.Button {
  @include mixins.remove-button-styles;
  @include mixins.remove-anchor-styles;

  // Layout;
  display: flex;
  height: 55px;
  padding: 16px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  // Style
  border-radius: 8px;

  // Typography
  font-weight: bold;

  &.primary {
    // Style
    background-color: theme.$color--red;
    border: none;
    color: #ffffff;
  }

  &.secondary {
    // Style
    border: 2px solid theme.$color--red;
    font-weight: bold;
    color: theme.$color--text;

    // Typography
    font-weight: normal;
  }

  &:disabled {
    // Style
    opacity: 0.5;

    // Interaction
    cursor: not-allowed;
  }
}
