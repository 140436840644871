@use '../../../styles/mixins.scss';

.MenuButton {
  @include mixins.remove-button-styles;

  // Box
  height: 2rem;
  // Fix width to avoid content shift when icon changes
  width: 2rem;

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;
}
