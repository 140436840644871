@use '../../../styles/theme.scss';

.FrameEditor {
  // Box
  width: 100%;

  .menu {
    // Layout
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}

.Frame {
  // Style
  background-color: #ffffff;

  &.draggingOver {
    // Style
    background-color: theme.$color--green-secondary;
  }
}
