@use '../styles/responsive.scss';

$editor--background-color: #f7f7f7;

.PostEditor {
  // Box
  min-height: 100vh;
  overflow: auto;

  // Skin
  background-color: $editor--background-color;

  @media (max-width: responsive.$mobile--max-width) {
    // Skin
    background-color: #fff;
  }
}

.Lightbox {
  // Box
  max-width: 800px;
  margin-top: 125px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  // Skin
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    width: 100%;
    max-width: none;
    margin: 0;
    // Leave room above to avoid overlap with floating FileMenu
    margin-top: 50px;

    // Skin
    box-shadow: none;
  }
}
