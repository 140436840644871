@mixin remove-anchor-styles {
  color: inherit;
  text-decoration: none;
}

@mixin remove-button-styles {
  background-color: inherit;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    outline: revert;
  }
}

@mixin remove-input-styles {
  // Box
  padding: 0;
  margin: 0;

  // Style
  outline: none;
  border: none;
  background: transparent;

  // Typography
  font: inherit;
  color: inherit;
  text-decoration: inherit;
  text-align: inherit;
  line-height: inherit;
}
