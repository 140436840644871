@use '../../../styles/mixins.scss';
@use '../../../styles/theme.scss';

.EditForm {
  // Layout
  // display: flex;
  // flex-direction: column;
  // gap: 0.5rem;

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    'field field'
    'error buttons';
  gap: 0.5rem;

  .field {
    // Box
    grid-area: field;
    padding: 0.5rem;

    // Layout
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0.5rem;

    // Style
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;

    // Typography
    font-size: 1rem;
    line-height: 110%;

    .textInput {
      @include mixins.remove-input-styles;

      // Typography
      color: #4f4f4f;
      line-height: 110%;
    }

    .rootDomain {
      // Box
      width: max-content;
      flex-grow: 0;

      // Typography
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .errorMessage {
    // Box
    grid-area: error;
    padding: 0 0.5rem;

    // Layout
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    // Typography
    color: theme.$color--red;

    .domainUnavailable {
      // Layout
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .buttonGroup {
    // Box
    grid-area: buttons;

    // Layout
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: baseline;
    gap: 0.5rem;
  }
}
