@use '../../../styles/theme.scss';

:global(.ProseMirror-gapcursor) {
  &:after {
    // Box
    height: 1rem;
    width: 2rem;
    margin-top: 0;

    // Style
    border: none;
    border-bottom: 2px solid theme.$color--text;
  }
}

.DropcursorExtension {
  // Style
  background-color: theme.$color--orange;
}
