@use '../../../styles/responsive.scss';

$drawer-width: 240px;

.MenuDrawer {
  // Box
  width: $drawer-width;
  height: 100%;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;

  // Layout
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  // Style
  background-color: #ffffff;

  // Animation
  transition: width 500ms;

  &.closed {
    // Box
    width: 0;
  }

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    position: absolute;
    z-index: 100;

    // Style
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .content {
    // Box
    width: $drawer-width;
    height: 100%;
  }
}
