@use '../../styles/responsive.scss';

.CenteredLayout {
  // Desktop layout

  // Box
  display: grid;
  grid-template-columns: auto max-content auto;
  grid-template-rows: 0.6fr 1fr 1.4fr;
  grid-template-areas:
    '. . .'
    '. content .'
    '. . .';

  width: 100%;
  height: 100vh;
  overflow-y: auto;

  .content {
    // Box
    grid-area: content;
  }

  @media (max-width: responsive.$mobile--max-width) {
    // Mobile layout

    // Box
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 0.6fr 1fr 1.4fr;
    grid-template-areas:
      '.'
      'content'
      '.';

    // Style
    background-color: #ffffff;
  }
}
