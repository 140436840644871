.TileGrid {
  --columns: 2;

  // Box
  width: 100%;
  max-width: 3050px;

  // Layout
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  grid-auto-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;

  @media (min-width: 420px) {
    --columns: 2;
  }

  @media (min-width: 650px) {
    --columns: 3;
  }

  @media (min-width: 860px) {
    --columns: 4;
  }

  @media (min-width: 1070px) {
    --columns: 5;
  }

  @media (min-width: 1270px) {
    --columns: 6;
  }

  @media (min-width: 1480px) {
    --columns: 7;
  }

  @media (min-width: 16800px) {
    --columns: 8;
  }

  @media (min-width: 1890px) {
    --columns: 9;
  }

  @media (min-width: 2100px) {
    --columns: 10;
  }
}
