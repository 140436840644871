@use '../styles/reset.scss';
@use '../styles/fonts.scss';

* {
  box-sizing: border-box;
}

html {
  // Set value of `rem` units
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

input {
  font-size: 1rem;
}
