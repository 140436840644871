@use '../../../styles/theme.scss';
@use '../../../styles/mixins.scss';

.Button {
  @include mixins.remove-button-styles;
  @include mixins.remove-anchor-styles;

  // Box
  padding: 0.75rem 2rem;
  width: max-content;

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;

  // Visual
  border-radius: 4px;

  // Typography
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;

  &.primary {
    // Visual
    background-color: theme.$color--red;

    // Typography
    color: theme.$color--white;
  }

  &.secondary {
    // Visual
    background-color: theme.$color--white;
    border: 1px solid theme.$color--red;

    // Typography
    color: theme.$color--red;
  }

  &:disabled {
    // Visual
    opacity: 0.5;

    // Interaction
    cursor: not-allowed;
  }

  &.small {
    // Box
    padding: 0.5rem 1rem;

    // Typography
    font-size: 1rem;
  }

  &.medium {
    // Box
    padding: 0.75rem 2rem;
  }
}
