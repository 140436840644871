// Foundations
$color--white: #ffffff;
$color--background: #f8fafa;
$color--stroke: #bdbdbd;
$color--text: #333333; // 80% black
$color--help-text: rgba(0, 0, 0, 0.4);

// Primary
$color--red: #ec3946; // Citrus Red
$color--orange: #ff9c00; // Citrus Orange
$color--green: #8bd643; // Light Green
$color--yellow: #ffe138; // Yellow

// Secondary
$color--red-secondary: #f3c2c6;
$color--orange-secondary: #ffe1b2;
$color--green-secondary: #dbe2d3;
$color--yellow-secondary: #fff2a9;

// Cards
$color--card: #fafaff;
$color--card-outline: #cbcbe6;
