@use '../../../styles/mixins.scss';
@use '../../../styles/responsive.scss';

$card-width: 464px;

.DomainCard {
  // Box
  width: $card-width;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.5rem;

  // Style
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.ViewCard {
  // Layout
  display: grid;
  grid-template-columns: 2rem max-content;
  grid-template-rows: auto auto;
  grid-template-areas:
    'icon domain'
    'icon details';
  column-gap: 0.75rem;
  row-gap: 0.25rem;

  .icon {
    // Box
    height: 100%;
    aspect-ratio: 1/1;

    // Layout
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .domain {
    // Layout
    grid-area: domain;

    // Typography
    color: #0d1216;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }

  .details {
    // Layout
    grid-area: details;

    // Typography
    color: rgba(13, 18, 22, 0.7);
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 120%;
  }

  .editButton {
    @include mixins.remove-button-styles;

    // Typography
    text-decoration: underline;
  }
}
