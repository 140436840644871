@use '../styles/responsive.scss';

.PublishSuccessModal {
  // Box
  padding: 48px 32px;

  // Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .close {
    // Box
    position: absolute;
    top: 20px;
    right: 20px;
    width: max-content;
    padding: 8px 16px;
  }

  .successAnimation {
    // Box
    max-width: 64px;
    max-height: 64px;
    margin-bottom: -4px;
  }

  .message {
    // Typography
    color: #000;
    text-align: center;
    font-size: 1.5rem;
  }

  .link {
    // Layout
    display: block;

    // Typography
    color: #0085ff;
    text-align: center;
    font-size: 1rem;
    text-decoration-line: underline;
  }

  @media (max-width: responsive.$mobile--max-width) {
    width: 350px;
    padding: 48px 16px;

    .message {
      font-size: 18px;
    }

    .link {
      font-size: 12px;
    }
  }
}
