@use '../../styles/responsive.scss';

.ModalDialogContainer {
  // Box
  position: absolute;
  inset: 0;

  // Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ModalDialog {
  // Box
  position: relative;
  width: max-content;
  max-width: min(responsive.$mobile--max-width, 100%);
  margin-top: -30dvh;

  // Style
  border-radius: 20px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  // Interaction
  pointer-events: all;
}
