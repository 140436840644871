@use '../../styles/responsive.scss';

.AuthCard {
  // Box
  width: 46rem;
  padding: 3rem 8rem 5rem;

  // Style
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 13px 17px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    width: 100%;
    padding: 1rem;

    // Style
    box-shadow: none;
  }
}
