@use '../../../../styles/mixins.scss';

.BlockMenu {
  // Box
  width: fit-content;
  padding: 0;

  // Layout
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  // Style
  background-color: transparent;
}

.MenuItem {
  @include mixins.remove-button-styles;

  // Box
  padding: 0 0.5rem;

  // Style
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.6);
  opacity: 0.5;

  // Typography
  color: rgba(0, 0, 0, 0.8);

  // Animation
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 1;
  }
}
