@use '../../styles/theme.scss';

.ErrorMessage {
  // Box
  overflow-x: auto;
  padding: 0.5rem;

  // Layout
  display: flex;
  flex-direction: column;
  gap: 1rem;

  // Style
  background-color: theme.$color--red-secondary;

  // Typography
  color: theme.$color--text;
  font-family: 'Courier New', Courier, monospace;

  .block {
    // Box
    width: max-content;
  }

  .heading {
    // Typography
    font-weight: 600;
    font-size: 1.5rem;
  }

  .subheading {
    // Typography
    font-weight: 600;
  }
}
