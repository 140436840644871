@use '../../../styles/theme.scss';

.RichTextEditor {
  // Box
  position: relative;
}

.TipTapEditor {
  // Style
  outline: none;

  &:global(.ProseMirror-focused) {
    // cSpell:words selectednode
    :global(.ProseMirror-selectednode) {
      // Box
      overflow: visible;
    }
  }
}

.Placeholder {
  // Box
  position: absolute;
  top: 0;
  left: 0;

  // Style
  opacity: 0.5;
}
