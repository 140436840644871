@use '../../styles/theme.scss';

.PostTile {
  // Box
  width: 100%;

  // Layout
  display: flex;
  flex-direction: column;

  // Style
  background-color: #ffffff;
  border-radius: 0.5rem;

  // Typography
  text-decoration: none;

  .metadata {
    // Box
    padding: 1rem 0;

    // Layout
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .thumbnail {
    // Box
    display: block;
    padding: 1rem;

    // Layout
    display: flex;
    justify-content: center;
    align-items: center;

    // Style
    background-color: theme.$color--orange-secondary;
    border-radius: 0.5rem;

    .icon {
      // Box
      height: 6rem;
      width: auto;
    }
  }
}

.truncated {
  // Box
  overflow: hidden;

  // Layout
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.Filename {
  // Typography
  color: theme.$color--text;
  font-size: 1rem;
  font-weight: 500;

  a {
    // Typography
    color: inherit;
    text-decoration: none;

    &:hover {
      // Typography
      text-decoration: underline;
    }
  }
}

.PublishedUrl {
  // Typography
  color: theme.$color--help-text;

  &.link {
    // Layout
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    // Typography
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    .externalLinkIcon {
      // Box
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }
  }
}
