@use '../../styles/responsive.scss';
@use '../../styles/theme.scss';

.HomeScreen {
  // Box
  width: 100%;
  min-width: 220px;
  min-height: 100vh;

  // Layout
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;

  .mainSection {
    // Box
    height: 100%;

    // Layout
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
  }
}

.TopBar {
  // Box
  width: 100%;
  padding: 0.75rem 1.5rem;
  z-index: 150;

  // Layout
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  grid-template-areas: 'menu logo . cta';
  align-items: center;
  column-gap: 1rem;

  // Style
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    padding: 0.5rem;

    // Layout
    grid-template-columns: 2rem 1fr 2rem;
    grid-template-areas: 'menu logo .';
    justify-items: center;
  }

  .menuButton {
    // Box
    grid-area: menu;
  }

  .actionButton {
    grid-area: cta;

    // Layout
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: responsive.$mobile--max-width) {
      // Layout
      display: none;
    }
  }

  .logo {
    // Box
    display: block;
    height: 2.5rem;
    width: auto;

    // Layout
    grid-area: logo;

    @media (max-width: responsive.$mobile--max-width) {
      // Box
      flex-grow: 1;
    }
  }
}

.SideBar {
  // Box
  flex-grow: 0 1;
  width: 260px;
  height: 100%;
  padding: 1rem;

  // Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .logoutButton {
    // Typography
    color: theme.$color--text;
  }
}

.ContentArea {
  // Box
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  height: 100%;
  padding: 1.5rem;

  // Layout
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    padding: 0.5rem;

    // Layout
    gap: 1rem;
  }
}
