.OverlayContainer {
  // Box
  position: relative;
  width: 100%;
  height: 100vh;

  .contentFrame {
    // Box
    width: 100%;
    height: 100%;

    // Layout
    overflow: auto;
  }
}

.OverlayFrame {
  // Box
  position: absolute;
  inset: 0;
  z-index: 999;

  // Interaction
  pointer-events: none;
}
