@use '../../styles/theme.scss';
@use '../../styles/mixins.scss';

.HeroBanner {
  // Box
  padding: 2rem;

  // Layout
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  // Style
  border-radius: 8px;
  background: linear-gradient(99deg, theme.$color--orange -4.19%, theme.$color--red 61.27%);

  .copy {
    // Layout
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    // Typography
    color: theme.$color--white;
    text-align: center;

    .heading {
      // Typography
      font-family: 'Chewy', 'Roboto', sans-serif;
      font-size: 3rem;
    }

    .tagline {
      // Typography
      font-size: 1.5rem;
    }
  }
}

// This is mostly copied from the Button element. Duplication is preferred to avoid complicating the common button with
// this was a one-off style.
.HeroButton {
  @include mixins.remove-button-styles;
  @include mixins.remove-anchor-styles;

  // Box
  padding: 0.5rem 2rem;
  width: max-content;

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;

  // Style
  background-color: theme.$color--yellow;
  border-radius: 0.25rem;

  // Typography
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
}
