@use '../../../../styles/mixins.scss';
@use '../../../../styles/theme.scss';

.FormattingMenu {
  // Box
  position: relative;
  width: max-content;
  padding: 0;

  // Layout
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;

  // Skin
  background-color: #555555;
  border-radius: 8px;

  // Typography
  color: theme.$color--white;

  // Fade in
  opacity: 1;
  transition: opacity 150ms ease-in;

  .button {
    @include mixins.remove-button-styles;

    // Box
    padding: 0.5rem 1rem;

    // Skin
    background-color: transparent;
  }

  // Add divider between buttons
  .button + .button {
    border-left: solid 1px theme.$color--white;
  }

  .linkMenu {
    // Box
    position: absolute;
    top: 4rem;
    left: 0;
  }
}
