@mixin Form {
  // Layout
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

@mixin Title {
  // Typography
  color: #4f4f4f;
  font-weight: bold;
}
