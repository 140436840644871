.ModalOverlay {
  // Box
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  // Interaction
  pointer-events: all;

  // Style
  background-color: rgba(85, 85, 85, 0.8);
}
