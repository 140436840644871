.KvDbEditor {
  // Box
  min-height: 100vh;

  // Layout
  display: grid;
  // TODO: Convert to % (of 1440px)
  grid-template-columns: auto [databases] minmax(max-content, 20%) 4rem [entries] minmax(max-content, 60%) auto;
  grid-template-rows: 115px [content] auto;

  .databases {
    // Box
    grid-column-start: databases;
    grid-row-start: content;
  }

  .detail {
    // Box
    grid-column-start: entries;
    grid-row-start: content;
  }

  ul {
    // Style
    margin-left: 2rem;
    list-style-type: disc;
  }
}
