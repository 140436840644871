@use '../styles/responsive.scss';

.FileMenu {
  // Box
  position: fixed;
  top: 30px;
  right: 35px;
  min-width: 360px;
  min-height: 64px;
  padding: 0.5rem 1rem;
  z-index: 50;

  // Layout
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  // Skin
  background: #fff;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);

  .location {
    // Layout
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .filename {
    flex: 1;
    display: flex;
    align-items: stretch;

    color: #555555;
    font-size: 1.5rem;
    line-height: 100%;

    .input {
      flex-grow: 0;
    }

    @media (max-width: responsive.$mobile--max-width) {
      font-size: 24px;
    }
  }

  .urlPath {
    // Layout
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    text-wrap: nowrap;

    // Typography
    font-size: 1rem;
    color: #6e6e6e;

    .link {
      // Typography
      color: #6e6e6e;
    }

    .openUrlIcon {
      // Box

      // Layout
      display: inline;

      // SVG
      fill: #6e6e6e;
    }
  }

  .buttons {
    flex-grow: 0;
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: center;

    .publishBtn {
      // Box
      width: 100%;
      height: auto;
      padding: 0.5rem 1rem;

      // Skin
      border-radius: 4px;
      border: 1px solid #a4a4a4;
      background-color: #ffffff;

      // Typography
      color: #6e6e6e;
      font-size: 1.5rem;
      text-align: center;
      text-decoration: none;

      // Interaction
      cursor: pointer;

      @media (max-width: responsive.$mobile--max-width) {
        // Box
        height: auto;
        padding: 6px 12px;

        // Typography
        font-size: 16px;
      }
    }
  }

  @media (max-width: responsive.$mobile--max-width) {
    // Box
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    min-height: 0;
    overflow: hidden;
    padding: 12px 24px;
    z-index: 100;

    // Layout
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Skin
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #dadada;

    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
