@use '../../styles/responsive.scss';

@mixin hide-on-tablet {
  @media (max-width: responsive.$tablet--max-width) {
    display: none;
  }
}

.CitrusSplashWallpaper {
  .content {
    // Box
    position: relative;
    inset: 0;
    overflow: auto;
  }
}

.Background {
  // Box
  display: block;
  position: absolute;
  inset: 0;
  z-index: -100;
  overflow: hidden;

  // Style
  background-color: #fff;

  &--citrusSplashSet {
    // Box
    position: absolute;
    width: max-content;
    height: max-content;
    bottom: -2%;
    left: 80%;
    margin-left: -400px;
    z-index: 0;
  }

  &--drippingWedge {
    // Box
    display: block;
    position: absolute;
    height: 520px;
    width: auto;
    top: -390px;
    left: 50%;
    margin-left: 200px;
    z-index: -100;
  }

  &--splashingWedge {
    // Box
    display: block;
    position: absolute;
    height: 45%;
    min-height: 200px;
    max-height: 520px;
    width: auto;
    top: 60%;
    left: 50%;
    margin-top: -100px;
    margin-left: -500px;
    z-index: 100;

    // Layout
    transform: scale(-1, 1) rotate(148deg);
  }

  &--splash {
    // Box
    display: block;
    position: absolute;
    top: 50%;
    left: 40%;
    margin-top: -300px;
    margin-left: -400px;
    z-index: 0;

    @include hide-on-tablet();
  }

  &--dropletXSmall {
    // Box
    display: block;
    position: absolute;
    top: -22px;
    left: 28%;
    z-index: -100;
  }

  &--dropletSmall {
    // Box
    display: block;
    position: absolute;
    top: 50%;
    left: 40%;
    margin-top: 200px;
    margin-left: -400px;
    z-index: -100;
  }

  &--dropletLarge {
    // Box
    display: block;
    position: absolute;
    top: 7%;
    left: 22%;
    z-index: -100;
  }
}

.CitrusSplashSet {
  // Box
  display: block;
  position: relative;
  width: 790px;
  height: 790px;

  // DEBUG
  // border: 1px solid #f00;

  &--splash {
    // Box
    display: block;
    position: absolute;
    top: 235px;
    left: 45px;
    z-index: 0;

    // Layout
    transform: scale(-1, 1) rotate(14.99deg);
  }

  &--wedge {
    // Box
    display: block;
    position: absolute;
    top: 255px;
    left: 470px;
    width: 200px;
    height: auto;
    z-index: 100;

    // Layout
    transform: scale(1, -1) rotate(44.32deg);
  }

  &--dropletXSmall {
    // Box
    display: block;
    position: absolute;
    top: 0;
    left: 450px;
    z-index: -100;
  }

  &--dropletSmall {
    // Box
    display: block;
    position: absolute;
    top: 403px;
    left: 728px;
    z-index: -100;
  }

  &--dropletLarge {
    // Box
    display: block;
    position: absolute;
    top: 77px;
    left: 472px;
    z-index: -100;
  }
}
