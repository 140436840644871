.DebugPanel {
  // Box
  position: absolute;
  inset: auto 0 0 0;

  // Layout
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  // Style
  background-color: #337ca0;

  // Typography
  color: #ffffff;

  // Interaction
  pointer-events: all;

  .titleBar {
    // Box
    padding: 4px;

    // Style
    background-color: rgba(255, 255, 255, 0.6);

    // Typography
    text-align: center;
    color: #555555;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    font-weight: bold;
  }

  .panels {
    // Box
    max-height: 20rem;
    padding: 1rem;
    overflow-y: auto;

    // Layout
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

.Panel {
  // Box
  width: 40rem;
  overflow: auto;
}

.ObjectInspector {
  // Box
  margin: 1rem 0;
  padding: 1rem;
  overflow: auto;

  // Style
  background-color: #e7e7e7;
  border: 1px solid #555555;

  // Typography
  color: #555555;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}
