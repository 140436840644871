.SignUpScreen {
  .logo {
    // Box
    display: block;
    height: 3rem;
    width: auto;
    margin: 0 auto;
  }

  .heading {
    height: 3.5rem;
    flex-shrink: 0;
    margin-top: 26px;
    margin-bottom: 49px;

    // Typography
    font-family: 'Chewy', 'Roboto', sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px; /* 120% */
    font-style: normal;
    text-align: center;
    color: #4f4f4f;
  }

  .error {
    color: #ff0000;
    padding: 8px;
  }
}
