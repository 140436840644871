.LinkMenu {
  // Box
  width: 400px;
  padding: 16px;

  // Layout
  display: flex;
  flex-direction: column;
  align-items: stretch;

  // Style
  background-color: #555555;
  border-radius: 0.5rem;

  .urlInput {
    // Box
    padding: 0.5rem;

    // Style
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;

    // Typography
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    font-weight: normal;

    &::placeholder {
      // Typography
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
