@use '../../styles/theme.scss';

.SelectableElement {
  &.editing {
    // Style
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 0;
    outline-color: theme.$color--green-secondary;
  }

  &.selected {
    // Style
    outline: 1px solid theme.$color--green;
  }

  &.canSelect:not(&.selected) {
    &:hover {
      // Style
      outline-width: 2px;
      outline-style: solid;
      outline-offset: 0;
      outline-color: theme.$color--green;
    }
  }
}
